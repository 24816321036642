// Animations
@keyframes swal2-toast-show {
  0% {
    transform: translateY(-0.625em) rotateZ(2deg);
  }

  33% {
    transform: translateY(0) rotateZ(-2deg);
  }

  66% {
    transform: translateY(0.3125em) rotateZ(2deg);
  }

  100% {
    transform: translateY(0) rotateZ(0deg);
  }
}

@keyframes swal2-toast-hide {
  100% {
    transform: rotateZ(1deg);
    opacity: 0;
  }
}

@keyframes swal2-toast-animate-success-line-tip {
  0% {
    top: 0.5625em;
    left: 0.0625em;
    width: 0;
  }

  54% {
    top: 0.125em;
    left: 0.125em;
    width: 0;
  }

  70% {
    top: 0.625em;
    left: -0.25em;
    width: 1.625em;
  }

  84% {
    top: 1.0625em;
    left: 0.75em;
    width: 0.5em;
  }

  100% {
    top: 1.125em;
    left: 0.1875em;
    width: 0.75em;
  }
}

@keyframes swal2-toast-animate-success-line-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0;
  }

  65% {
    top: 1.25em;
    right: 0.9375em;
    width: 0;
  }

  84% {
    top: 0.9375em;
    right: 0;
    width: 1.125em;
  }

  100% {
    top: 0.9375em;
    right: 0.1875em;
    width: 1.375em;
  }
}
