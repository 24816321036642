@font-face {
  font-family: "themify";
  src:url('./themify.eot?-fvbane');
  src:url('./themify.eot?#iefix-fvbane') format('embedded-opentype'),
    url('./themify.woff?-fvbane') format('woff'),
    url('./themify.ttf?-fvbane') format('truetype'),
    url('./themify.svg?-fvbane#themify') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="fyicon_"], [class*=" fyicon_"] {
	font-family: 'themify';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.fyicon_wand:before {
	content: "\e600";
}
.fyicon_volume:before {
	content: "\e601";
}
.fyicon_user:before {
	content: "\e602";
}
.fyicon_unlock:before {
	content: "\e603";
}
.fyicon_unlink:before {
	content: "\e604";
}
.fyicon_trash:before {
	content: "\e605";
}
.fyicon_thought:before {
	content: "\e606";
}
.fyicon_target:before {
	content: "\e607";
}
.fyicon_tag:before {
	content: "\e608";
}
.fyicon_tablet:before {
	content: "\e609";
}
.fyicon_star:before {
	content: "\e60a";
}
.fyicon_spray:before {
	content: "\e60b";
}
.fyicon_signal:before {
	content: "\e60c";
}
.fyicon_shopping-cart:before {
	content: "\e60d";
}
.fyicon_shopping-cart-full:before {
	content: "\e60e";
}
.fyicon_settings:before {
	content: "\e60f";
}
.fyicon_search:before {
	content: "\e610";
}
.fyicon_zoom-in:before {
	content: "\e611";
}
.fyicon_zoom-out:before {
	content: "\e612";
}
.fyicon_cut:before {
	content: "\e613";
}
.fyicon_ruler:before {
	content: "\e614";
}
.fyicon_ruler-pencil:before {
	content: "\e615";
}
.fyicon_ruler-alt:before {
	content: "\e616";
}
.fyicon_bookmark:before {
	content: "\e617";
}
.fyicon_bookmark-alt:before {
	content: "\e618";
}
.fyicon_reload:before {
	content: "\e619";
}
.fyicon_plus:before {
	content: "\e61a";
}
.fyicon_pin:before {
	content: "\e61b";
}
.fyicon_pencil:before {
	content: "\e61c";
}
.fyicon_pencil-alt:before {
	content: "\e61d";
}
.fyicon_paint-roller:before {
	content: "\e61e";
}
.fyicon_paint-bucket:before {
	content: "\e61f";
}
.fyicon_na:before {
	content: "\e620";
}
.fyicon_mobile:before {
	content: "\e621";
}
.fyicon_minus:before {
	content: "\e622";
}
.fyicon_medall:before {
	content: "\e623";
}
.fyicon_medall-alt:before {
	content: "\e624";
}
.fyicon_marker:before {
	content: "\e625";
}
.fyicon_marker-alt:before {
	content: "\e626";
}
.fyicon_arrow-up:before {
	content: "\e627";
}
.fyicon_arrow-right:before {
	content: "\e628";
}
.fyicon_arrow-left:before {
	content: "\e629";
}
.fyicon_arrow-down:before {
	content: "\e62a";
}
.fyicon_lock:before {
	content: "\e62b";
}
.fyicon_location-arrow:before {
	content: "\e62c";
}
.fyicon_link:before {
	content: "\e62d";
}
.fyicon_layout:before {
	content: "\e62e";
}
.fyicon_layers:before {
	content: "\e62f";
}
.fyicon_layers-alt:before {
	content: "\e630";
}
.fyicon_key:before {
	content: "\e631";
}
.fyicon_import:before {
	content: "\e632";
}
.fyicon_image:before {
	content: "\e633";
}
.fyicon_heart:before {
	content: "\e634";
}
.fyicon_heart-broken:before {
	content: "\e635";
}
.fyicon_hand-stop:before {
	content: "\e636";
}
.fyicon_hand-open:before {
	content: "\e637";
}
.fyicon_hand-drag:before {
	content: "\e638";
}
.fyicon_folder:before {
	content: "\e639";
}
.fyicon_flag:before {
	content: "\e63a";
}
.fyicon_flag-alt:before {
	content: "\e63b";
}
.fyicon_flag-alt-2:before {
	content: "\e63c";
}
.fyicon_eye:before {
	content: "\e63d";
}
.fyicon_export:before {
	content: "\e63e";
}
.fyicon_exchange-vertical:before {
	content: "\e63f";
}
.fyicon_desktop:before {
	content: "\e640";
}
.fyicon_cup:before {
	content: "\e641";
}
.fyicon_crown:before {
	content: "\e642";
}
.fyicon_comments:before {
	content: "\e643";
}
.fyicon_comment:before {
	content: "\e644";
}
.fyicon_comment-alt:before {
	content: "\e645";
}
.fyicon_close:before {
	content: "\e646";
}
.fyicon_clip:before {
	content: "\e647";
}
.fyicon_angle-up:before {
	content: "\e648";
}
.fyicon_angle-right:before {
	content: "\e649";
}
.fyicon_angle-left:before {
	content: "\e64a";
}
.fyicon_angle-down:before {
	content: "\e64b";
}
.fyicon_check:before {
	content: "\e64c";
}
.fyicon_check-box:before {
	content: "\e64d";
}
.fyicon_camera:before {
	content: "\e64e";
}
.fyicon_announcement:before {
	content: "\e64f";
}
.fyicon_brush:before {
	content: "\e650";
}
.fyicon_briefcase:before {
	content: "\e651";
}
.fyicon_bolt:before {
	content: "\e652";
}
.fyicon_bolt-alt:before {
	content: "\e653";
}
.fyicon_blackboard:before {
	content: "\e654";
}
.fyicon_bag:before {
	content: "\e655";
}
.fyicon_move:before {
	content: "\e656";
}
.fyicon_arrows-vertical:before {
	content: "\e657";
}
.fyicon_arrows-horizontal:before {
	content: "\e658";
}
.fyicon_fullscreen:before {
	content: "\e659";
}
.fyicon_arrow-top-right:before {
	content: "\e65a";
}
.fyicon_arrow-top-left:before {
	content: "\e65b";
}
.fyicon_arrow-circle-up:before {
	content: "\e65c";
}
.fyicon_arrow-circle-right:before {
	content: "\e65d";
}
.fyicon_arrow-circle-left:before {
	content: "\e65e";
}
.fyicon_arrow-circle-down:before {
	content: "\e65f";
}
.fyicon_angle-double-up:before {
	content: "\e660";
}
.fyicon_angle-double-right:before {
	content: "\e661";
}
.fyicon_angle-double-left:before {
	content: "\e662";
}
.fyicon_angle-double-down:before {
	content: "\e663";
}
.fyicon_zip:before {
	content: "\e664";
}
.fyicon_world:before {
	content: "\e665";
}
.fyicon_wheelchair:before {
	content: "\e666";
}
.fyicon_view-list:before {
	content: "\e667";
}
.fyicon_view-list-alt:before {
	content: "\e668";
}
.fyicon_view-grid:before {
	content: "\e669";
}
.fyicon_uppercase:before {
	content: "\e66a";
}
.fyicon_upload:before {
	content: "\e66b";
}
.fyicon_underline:before {
	content: "\e66c";
}
.fyicon_truck:before {
	content: "\e66d";
}
.fyicon_timer:before {
	content: "\e66e";
}
.fyicon_ticket:before {
	content: "\e66f";
}
.fyicon_thumb-up:before {
	content: "\e670";
}
.fyicon_thumb-down:before {
	content: "\e671";
}
.fyicon_text:before {
	content: "\e672";
}
.fyicon_stats-up:before {
	content: "\e673";
}
.fyicon_stats-down:before {
	content: "\e674";
}
.fyicon_split-v:before {
	content: "\e675";
}
.fyicon_split-h:before {
	content: "\e676";
}
.fyicon_smallcap:before {
	content: "\e677";
}
.fyicon_shine:before {
	content: "\e678";
}
.fyicon_shift-right:before {
	content: "\e679";
}
.fyicon_shift-left:before {
	content: "\e67a";
}
.fyicon_shield:before {
	content: "\e67b";
}
.fyicon_notepad:before {
	content: "\e67c";
}
.fyicon_server:before {
	content: "\e67d";
}
.fyicon_quote-right:before {
	content: "\e67e";
}
.fyicon_quote-left:before {
	content: "\e67f";
}
.fyicon_pulse:before {
	content: "\e680";
}
.fyicon_printer:before {
	content: "\e681";
}
.fyicon_power-off:before {
	content: "\e682";
}
.fyicon_plug:before {
	content: "\e683";
}
.fyicon_pie-chart:before {
	content: "\e684";
}
.fyicon_paragraph:before {
	content: "\e685";
}
.fyicon_panel:before {
	content: "\e686";
}
.fyicon_package:before {
	content: "\e687";
}
.fyicon_music:before {
	content: "\e688";
}
.fyicon_music-alt:before {
	content: "\e689";
}
.fyicon_mouse:before {
	content: "\e68a";
}
.fyicon_mouse-alt:before {
	content: "\e68b";
}
.fyicon_money:before {
	content: "\e68c";
}
.fyicon_microphone:before {
	content: "\e68d";
}
.fyicon_menu:before {
	content: "\e68e";
}
.fyicon_menu-alt:before {
	content: "\e68f";
}
.fyicon_map:before {
	content: "\e690";
}
.fyicon_map-alt:before {
	content: "\e691";
}
.fyicon_loop:before {
	content: "\e692";
}
.fyicon_location-pin:before {
	content: "\e693";
}
.fyicon_list:before {
	content: "\e694";
}
.fyicon_light-bulb:before {
	content: "\e695";
}
.fyicon_Italic:before {
	content: "\e696";
}
.fyicon_info:before {
	content: "\e697";
}
.fyicon_infinite:before {
	content: "\e698";
}
.fyicon_id-badge:before {
	content: "\e699";
}
.fyicon_hummer:before {
	content: "\e69a";
}
.fyicon_home:before {
	content: "\e69b";
}
.fyicon_help:before {
	content: "\e69c";
}
.fyicon_headphone:before {
	content: "\e69d";
}
.fyicon_harddrives:before {
	content: "\e69e";
}
.fyicon_harddrive:before {
	content: "\e69f";
}
.fyicon_gift:before {
	content: "\e6a0";
}
.fyicon_game:before {
	content: "\e6a1";
}
.fyicon_filter:before {
	content: "\e6a2";
}
.fyicon_files:before {
	content: "\e6a3";
}
.fyicon_file:before {
	content: "\e6a4";
}
.fyicon_eraser:before {
	content: "\e6a5";
}
.fyicon_envelope:before {
	content: "\e6a6";
}
.fyicon_download:before {
	content: "\e6a7";
}
.fyicon_direction:before {
	content: "\e6a8";
}
.fyicon_direction-alt:before {
	content: "\e6a9";
}
.fyicon_dashboard:before {
	content: "\e6aa";
}
.fyicon_control-stop:before {
	content: "\e6ab";
}
.fyicon_control-shuffle:before {
	content: "\e6ac";
}
.fyicon_control-play:before {
	content: "\e6ad";
}
.fyicon_control-pause:before {
	content: "\e6ae";
}
.fyicon_control-forward:before {
	content: "\e6af";
}
.fyicon_control-backward:before {
	content: "\e6b0";
}
.fyicon_cloud:before {
	content: "\e6b1";
}
.fyicon_cloud-up:before {
	content: "\e6b2";
}
.fyicon_cloud-down:before {
	content: "\e6b3";
}
.fyicon_clipboard:before {
	content: "\e6b4";
}
.fyicon_car:before {
	content: "\e6b5";
}
.fyicon_calendar:before {
	content: "\e6b6";
}
.fyicon_book:before {
	content: "\e6b7";
}
.fyicon_bell:before {
	content: "\e6b8";
}
.fyicon_basketball:before {
	content: "\e6b9";
}
.fyicon_bar-chart:before {
	content: "\e6ba";
}
.fyicon_bar-chart-alt:before {
	content: "\e6bb";
}
.fyicon_back-right:before {
	content: "\e6bc";
}
.fyicon_back-left:before {
	content: "\e6bd";
}
.fyicon_arrows-corner:before {
	content: "\e6be";
}
.fyicon_archive:before {
	content: "\e6bf";
}
.fyicon_anchor:before {
	content: "\e6c0";
}
.fyicon_align-right:before {
	content: "\e6c1";
}
.fyicon_align-left:before {
	content: "\e6c2";
}
.fyicon_align-justify:before {
	content: "\e6c3";
}
.fyicon_align-center:before {
	content: "\e6c4";
}
.fyicon_alert:before {
	content: "\e6c5";
}
.fyicon_alarm-clock:before {
	content: "\e6c6";
}
.fyicon_agenda:before {
	content: "\e6c7";
}
.fyicon_write:before {
	content: "\e6c8";
}
.fyicon_window:before {
	content: "\e6c9";
}
.fyicon_widgetized:before {
	content: "\e6ca";
}
.fyicon_widget:before {
	content: "\e6cb";
}
.fyicon_widget-alt:before {
	content: "\e6cc";
}
.fyicon_wallet:before {
	content: "\e6cd";
}
.fyicon_video-clapper:before {
	content: "\e6ce";
}
.fyicon_video-camera:before {
	content: "\e6cf";
}
.fyicon_vector:before {
	content: "\e6d0";
}
.fyicon_themify-logo:before {
	content: "\e6d1";
}
.fyicon_themify-favicon:before {
	content: "\e6d2";
}
.fyicon_themify-favicon-alt:before {
	content: "\e6d3";
}
.fyicon_support:before {
	content: "\e6d4";
}
.fyicon_stamp:before {
	content: "\e6d5";
}
.fyicon_split-v-alt:before {
	content: "\e6d6";
}
.fyicon_slice:before {
	content: "\e6d7";
}
.fyicon_shortcode:before {
	content: "\e6d8";
}
.fyicon_shift-right-alt:before {
	content: "\e6d9";
}
.fyicon_shift-left-alt:before {
	content: "\e6da";
}
.fyicon_ruler-alt-2:before {
	content: "\e6db";
}
.fyicon_receipt:before {
	content: "\e6dc";
}
.fyicon_pin2:before {
	content: "\e6dd";
}
.fyicon_pin-alt:before {
	content: "\e6de";
}
.fyicon_pencil-alt2:before {
	content: "\e6df";
}
.fyicon_palette:before {
	content: "\e6e0";
}
.fyicon_more:before {
	content: "\e6e1";
}
.fyicon_more-alt:before {
	content: "\e6e2";
}
.fyicon_microphone-alt:before {
	content: "\e6e3";
}
.fyicon_magnet:before {
	content: "\e6e4";
}
.fyicon_line-double:before {
	content: "\e6e5";
}
.fyicon_line-dotted:before {
	content: "\e6e6";
}
.fyicon_line-dashed:before {
	content: "\e6e7";
}
.fyicon_layout-width-full:before {
	content: "\e6e8";
}
.fyicon_layout-width-default:before {
	content: "\e6e9";
}
.fyicon_layout-width-default-alt:before {
	content: "\e6ea";
}
.fyicon_layout-tab:before {
	content: "\e6eb";
}
.fyicon_layout-tab-window:before {
	content: "\e6ec";
}
.fyicon_layout-tab-v:before {
	content: "\e6ed";
}
.fyicon_layout-tab-min:before {
	content: "\e6ee";
}
.fyicon_layout-slider:before {
	content: "\e6ef";
}
.fyicon_layout-slider-alt:before {
	content: "\e6f0";
}
.fyicon_layout-sidebar-right:before {
	content: "\e6f1";
}
.fyicon_layout-sidebar-none:before {
	content: "\e6f2";
}
.fyicon_layout-sidebar-left:before {
	content: "\e6f3";
}
.fyicon_layout-placeholder:before {
	content: "\e6f4";
}
.fyicon_layout-menu:before {
	content: "\e6f5";
}
.fyicon_layout-menu-v:before {
	content: "\e6f6";
}
.fyicon_layout-menu-separated:before {
	content: "\e6f7";
}
.fyicon_layout-menu-full:before {
	content: "\e6f8";
}
.fyicon_layout-media-right-alt:before {
	content: "\e6f9";
}
.fyicon_layout-media-right:before {
	content: "\e6fa";
}
.fyicon_layout-media-overlay:before {
	content: "\e6fb";
}
.fyicon_layout-media-overlay-alt:before {
	content: "\e6fc";
}
.fyicon_layout-media-overlay-alt-2:before {
	content: "\e6fd";
}
.fyicon_layout-media-left-alt:before {
	content: "\e6fe";
}
.fyicon_layout-media-left:before {
	content: "\e6ff";
}
.fyicon_layout-media-center-alt:before {
	content: "\e700";
}
.fyicon_layout-media-center:before {
	content: "\e701";
}
.fyicon_layout-list-thumb:before {
	content: "\e702";
}
.fyicon_layout-list-thumb-alt:before {
	content: "\e703";
}
.fyicon_layout-list-post:before {
	content: "\e704";
}
.fyicon_layout-list-large-image:before {
	content: "\e705";
}
.fyicon_layout-line-solid:before {
	content: "\e706";
}
.fyicon_layout-grid4:before {
	content: "\e707";
}
.fyicon_layout-grid3:before {
	content: "\e708";
}
.fyicon_layout-grid2:before {
	content: "\e709";
}
.fyicon_layout-grid2-thumb:before {
	content: "\e70a";
}
.fyicon_layout-cta-right:before {
	content: "\e70b";
}
.fyicon_layout-cta-left:before {
	content: "\e70c";
}
.fyicon_layout-cta-center:before {
	content: "\e70d";
}
.fyicon_layout-cta-btn-right:before {
	content: "\e70e";
}
.fyicon_layout-cta-btn-left:before {
	content: "\e70f";
}
.fyicon_layout-column4:before {
	content: "\e710";
}
.fyicon_layout-column3:before {
	content: "\e711";
}
.fyicon_layout-column2:before {
	content: "\e712";
}
.fyicon_layout-accordion-separated:before {
	content: "\e713";
}
.fyicon_layout-accordion-merged:before {
	content: "\e714";
}
.fyicon_layout-accordion-list:before {
	content: "\e715";
}
.fyicon_ink-pen:before {
	content: "\e716";
}
.fyicon_info-alt:before {
	content: "\e717";
}
.fyicon_help-alt:before {
	content: "\e718";
}
.fyicon_headphone-alt:before {
	content: "\e719";
}
.fyicon_hand-point-up:before {
	content: "\e71a";
}
.fyicon_hand-point-right:before {
	content: "\e71b";
}
.fyicon_hand-point-left:before {
	content: "\e71c";
}
.fyicon_hand-point-down:before {
	content: "\e71d";
}
.fyicon_gallery:before {
	content: "\e71e";
}
.fyicon_face-smile:before {
	content: "\e71f";
}
.fyicon_face-sad:before {
	content: "\e720";
}
.fyicon_credit-card:before {
	content: "\e721";
}
.fyicon_control-skip-forward:before {
	content: "\e722";
}
.fyicon_control-skip-backward:before {
	content: "\e723";
}
.fyicon_control-record:before {
	content: "\e724";
}
.fyicon_control-eject:before {
	content: "\e725";
}
.fyicon_comments-smiley:before {
	content: "\e726";
}
.fyicon_brush-alt:before {
	content: "\e727";
}
.fyicon_youtube:before {
	content: "\e728";
}
.fyicon_vimeo:before {
	content: "\e729";
}
.fyicon_twitter:before {
	content: "\e72a";
}
.fyicon_time:before {
	content: "\e72b";
}
.fyicon_tumblr:before {
	content: "\e72c";
}
.fyicon_skype:before {
	content: "\e72d";
}
.fyicon_share:before {
	content: "\e72e";
}
.fyicon_share-alt:before {
	content: "\e72f";
}
.fyicon_rocket:before {
	content: "\e730";
}
.fyicon_pinterest:before {
	content: "\e731";
}
.fyicon_new-window:before {
	content: "\e732";
}
.fyicon_microsoft:before {
	content: "\e733";
}
.fyicon_list-ol:before {
	content: "\e734";
}
.fyicon_linkedin:before {
	content: "\e735";
}
.fyicon_layout-sidebar-2:before {
	content: "\e736";
}
.fyicon_layout-grid4-alt:before {
	content: "\e737";
}
.fyicon_layout-grid3-alt:before {
	content: "\e738";
}
.fyicon_layout-grid2-alt:before {
	content: "\e739";
}
.fyicon_layout-column4-alt:before {
	content: "\e73a";
}
.fyicon_layout-column3-alt:before {
	content: "\e73b";
}
.fyicon_layout-column2-alt:before {
	content: "\e73c";
}
.fyicon_instagram:before {
	content: "\e73d";
}
.fyicon_google:before {
	content: "\e73e";
}
.fyicon_github:before {
	content: "\e73f";
}
.fyicon_flickr:before {
	content: "\e740";
}
.fyicon_facebook:before {
	content: "\e741";
}
.fyicon_dropbox:before {
	content: "\e742";
}
.fyicon_dribbble:before {
	content: "\e743";
}
.fyicon_apple:before {
	content: "\e744";
}
.fyicon_android:before {
	content: "\e745";
}
.fyicon_save:before {
	content: "\e746";
}
.fyicon_save-alt:before {
	content: "\e747";
}
.fyicon_yahoo:before {
	content: "\e748";
}
.fyicon_wordpress:before {
	content: "\e749";
}
.fyicon_vimeo-alt:before {
	content: "\e74a";
}
.fyicon_twitter-alt:before {
	content: "\e74b";
}
.fyicon_tumblr-alt:before {
	content: "\e74c";
}
.fyicon_trello:before {
	content: "\e74d";
}
.fyicon_stack-overflow:before {
	content: "\e74e";
}
.fyicon_soundcloud:before {
	content: "\e74f";
}
.fyicon_sharethis:before {
	content: "\e750";
}
.fyicon_sharethis-alt:before {
	content: "\e751";
}
.fyicon_reddit:before {
	content: "\e752";
}
.fyicon_pinterest-alt:before {
	content: "\e753";
}
.fyicon_microsoft-alt:before {
	content: "\e754";
}
.fyicon_linux:before {
	content: "\e755";
}
.fyicon_jsfiddle:before {
	content: "\e756";
}
.fyicon_joomla:before {
	content: "\e757";
}
.fyicon_html5:before {
	content: "\e758";
}
.fyicon_flickr-alt:before {
	content: "\e759";
}
.fyicon_email:before {
	content: "\e75a";
}
.fyicon_drupal:before {
	content: "\e75b";
}
.fyicon_dropbox-alt:before {
	content: "\e75c";
}
.fyicon_css3:before {
	content: "\e75d";
}
.fyicon_rss:before {
	content: "\e75e";
}
.fyicon_rss-alt:before {
	content: "\e75f";
}
