@tailwind base;
@tailwind components;
@tailwind utilities;

/*
* Tailwind custom
*/
.alert {
	@apply text-center rounded font-medium px-3 py-2 text-lg;
}
.alert-danger {
	@apply bg-red-200 text-red-500;
}
.alert-success {
	@apply bg-green-200 text-green-500;
}
.btn-action {
	@apply py-2 px-3.5 rounded bg-gray-100 text-xs leading-none text-gray-600 inline-flex items-center justify-center gap-[0.25rem];
	&.success {
		@apply bg-emerald-50 text-emerald-400;
		&:hover {
			@apply bg-emerald-100;
		}
	}
	&.active {
		@apply bg-green-50 text-green-400;
		&:hover {
			@apply bg-green-100;
		}
	}
	&.danger {
		@apply bg-red-50 text-red-400;
		&:hover {
			@apply bg-red-100;
		}
	}
	&.warning {
		@apply bg-amber-50 text-amber-400;
		&:hover {
			@apply bg-amber-100;
		}
	}
	&.info {
		@apply bg-sky-50 text-sky-400;
		&:hover {
			@apply bg-sky-100;
		}
	}
	&.custom-1 {
		@apply bg-blue-50 text-blue-400;
		&:hover {
			@apply bg-blue-100;
		}
	}
}
.input_group {
	@apply relative w-full;
	&.space_all {
		@apply px-4;
	}
	&:not(.no_space) {
		@apply py-4;
	}
	label {
		@apply text-base m-0 mb-1 block p-0;
	}
	input,
	select {
		border: 1px solid #f1f1f1;
		border-radius: 8px;
		line-height: normal;
		display: block;
		padding: 10px 15px;
		width: 100%;
		@apply text-base;
		&:focus {
			outline: 2px solid var(--primary-color);
			border: 1px solid #f1f1f1;
			box-shadow: none;
		}
	}
	.input_hide {
		@apply absolute top-1/2 right-2 -translate-y-1/2 text-base w-[32px] h-[32px] flex items-center justify-center rounded-full bg-white cursor-pointer text-black;
	}
	&.item__transparent {
		input,
		select {
			border-color: var(--second-color);
			background: transparent;
			color: #fff;
		}
		.input_hide {
			background-color: var(--second-color);
			color: #fff;
		}
		&.border__third {
			input,
			select {
				border-color: var(--third-color);
			}
		}
	}
}

body.webapp-site {
	input {
		@apply focus:ring-0;
	}
	.style_b-notifications:not(:last-child) .item_b-style-notification {
		@apply before:w-[1px] before:bg-gray-300 before:h-[calc(100%_+_1rem)] before:absolute before:top-[calc(100%_+_2px)] before:-translate-y-1/2 before:right-[calc(100%_-_10.8rem)] before:translate-x-1/2 before:z-[-1];
	}
	.toast-container {
		padding: 10px;
		max-width: 100%;
		&.toast-top-right {
			top: 0;
			right: 0;
		}
		&.toast-top-left {
			top: 0;
			left: 0;
		}
		&.toast-bottom-right {
			bottom: 0;
			right: 0;
		}
		&.toast-bottom-left {
			bottom: 0;
			left: 0;
		}
		div[custom-toast-component],
		.ngx-toastr {
			border: 0;
			max-width: 100%;
			background: transparent !important;
			@apply shadow-none mt-0 ml-0 mr-0 mb-2 p-0 overflow-auto drop-shadow-[0_3px_5px_rgba(100,100,100,0.5)];
			&.toast-error {
				@apply text-gray-700;
				.toaster {
					@apply bg-red-100;
					.toast-icon {
						@apply bg-red-400;
					}
				}
				.close-icon {
					@apply text-red-400;
				}
			}
			&.toast-success {
				@apply text-gray-700;
				.toaster {
					@apply bg-green-100;
					.toast-icon {
						@apply bg-green-400;
					}
				}
				.close-icon {
					@apply text-green-400;
				}
			}
			&.toast-info {
				@apply text-gray-700;
				.toaster {
					@apply bg-sky-100;
					.toast-icon {
						@apply bg-sky-400;
					}
				}
				.close-icon {
					@apply text-sky-400;
				}
			}
			&.toast-warning {
				@apply text-gray-700;
				.toaster {
					@apply bg-yellow-100;
					.toast-icon {
						@apply bg-yellow-400;
					}
				}
				.close-icon {
					@apply text-yellow-400;
				}
			}
			.toaster {
				@apply grid grid-cols-[minmax(0,50px)_minmax(0,1fr)_minmax(0,25px)] pr-2 text-white bg-[#333333] w-[340px] max-w-full min-h-[80px] rounded-lg shadow-none m-0 overflow-hidden;
				.toast-icon {
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 26px;
					@apply row-span-2;
				}
				.toast-details {
					@apply text-base p-4 text-left;
				}
				.close-icon {
					@apply self-center;
					a {
						color: currentColor;
						@apply relative top-[unset] bottom-[unset] left-[unset] right-[unset] block m-0 w-full text-center float-none;
					}
				}
				.toast-details {
					@apply text-gray-700;
					.toast-title {
						@apply text-base font-semibold;
					}
					.toast-message {
						@apply text-sm;
					}
				}
				.progress-content {
					@apply col-start-2 col-end-3 relative block w-full;
				}
			}
		}
	}
	.input__date {
		&.type_full {
			.input__date-item {
				box-sizing: border-box;
				@apply block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base border-none shadow-none focus:border-[#F1F1F1] focus:ring-0;
			}
			mat-datepicker-toggle {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transform: none;
				button {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border-radius: 0;
					svg {
						position: absolute;
						top: 50%;
						right: 10px;
						transform: translateY(-50%);
						width: 25px;
						height: 25px;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					.mat-mdc-button-persistent-ripple {
						border-radius: 0;
					}
				}
			}
		}
	}
	.custom__mat-select {
		&.bg__white .mat-mdc-select-trigger {
			background-color: #fff;
		}
		&.bg__transparent {
			&.border__secondary {
				.mat-mdc-select-trigger {
					border-color: var(--second-color);
				}
			}
			.mat-mdc-select-placeholder {
				color: #6b7280;
			}
			.mat-mdc-select-arrow {
				--mat-select-enabled-arrow-color: #ddd;
			}
		}
		.mat-mdc-select-trigger {
			@apply m-0 w-full bg-slate-50 rounded py-2 px-4 text-base border shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0;
		}
	}
}

.input_hide {
	@apply absolute top-1/2 right-2 -translate-y-1/2 text-base w-[32px] h-[32px] flex items-center justify-center rounded-full bg-white cursor-pointer;
}