// https://stackoverflow.com/a/12335841/1331425
@function strip-units($number) {
  @return math.div($number, ($number * 0 + 1));
}
$icon-zoom: math.div(strip-units($swal2-icon-size), 5);

div:where(.swal2-icon) {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: $swal2-icon-size;
  height: $swal2-icon-size;
  margin: $swal2-icon-margin;
  zoom: $swal2-icon-zoom;
  border: #{0.25em * $icon-zoom} solid transparent;
  border-radius: 50%;
  border-color: $swal2-icon-border-color;
  font-family: $swal2-icon-font-family;
  line-height: $swal2-icon-size;
  cursor: default;
  user-select: none;

  .swal2-icon-content {
    display: flex;
    align-items: center;
    font-size: $swal2-icon-font-size;
  }

  &.swal2-error {
    border-color: $swal2-error;
    color: $swal2-error;

    .swal2-x-mark {
      position: relative;
      flex-grow: 1;

      @if $icon-zoom != 1 {
        zoom: $icon-zoom;
      }
    }

    [class^='swal2-x-mark-line'] {
      display: block;
      position: absolute;
      top: 2.3125em;
      width: 2.9375em;
      height: 0.3125em;
      border-radius: 0.125em;
      background-color: $swal2-error;

      &[class$='left'] {
        left: 1.0625em;
        transform: rotate(45deg);
      }

      &[class$='right'] {
        right: 1em;
        transform: rotate(-45deg);
      }
    }

    // Error icon animation
    &.swal2-icon-show {
      @if $swal2-icon-animations {
        animation: swal2-animate-error-icon 0.5s;

        .swal2-x-mark {
          animation: swal2-animate-error-x-mark 0.5s;
        }
      }
    }
  }

  &.swal2-warning {
    border-color: lighten($swal2-warning, 7);
    color: $swal2-warning;

    // Warning icon animation
    &.swal2-icon-show {
      @if $swal2-icon-animations {
        animation: swal2-animate-error-icon 0.5s;

        .swal2-icon-content {
          animation: swal2-animate-i-mark 0.5s;
        }
      }
    }
  }

  &.swal2-info {
    border-color: lighten($swal2-info, 20);
    color: $swal2-info;

    // Info icon animation
    &.swal2-icon-show {
      @if $swal2-icon-animations {
        animation: swal2-animate-error-icon 0.5s;

        .swal2-icon-content {
          animation: swal2-animate-i-mark 0.8s;
        }
      }
    }
  }

  &.swal2-question {
    border-color: lighten($swal2-question, 20);
    color: $swal2-question;

    // Question icon animation
    &.swal2-icon-show {
      @if $swal2-icon-animations {
        animation: swal2-animate-error-icon 0.5s;

        .swal2-icon-content {
          animation: swal2-animate-question-mark 0.8s;
        }
      }
    }
  }

  &.swal2-success {
    border-color: $swal2-success;
    color: $swal2-success;

    [class^='swal2-success-circular-line'] {
      // Emulate moving circular line
      position: absolute;
      width: 3.75em;
      height: 7.5em;
      border-radius: 50%;

      &[class$='left'] {
        top: -0.4375em;
        left: -2.0635em;
        transform: rotate(-45deg);
        transform-origin: 3.75em 3.75em;
        border-radius: 7.5em 0 0 7.5em;

        @if $icon-zoom != 1 {
          zoom: $icon-zoom;
        }
      }

      &[class$='right'] {
        top: -0.6875em;
        left: 1.875em;
        transform: rotate(-45deg);
        transform-origin: 0 3.75em;
        border-radius: 0 7.5em 7.5em 0;

        @if $icon-zoom != 1 {
          zoom: $icon-zoom;
        }
      }
    }

    .swal2-success-ring {
      // Ring
      position: absolute;
      z-index: 2;
      top: -0.25em;
      left: -0.25em;
      box-sizing: content-box;
      width: 100%;
      height: 100%;
      border: 0.25em solid $swal2-success-border;
      border-radius: 50%;

      @if $icon-zoom != 1 {
        zoom: $icon-zoom;
      }
    }

    .swal2-success-fix {
      // Hide corners left from animation
      position: absolute;
      z-index: 1;
      top: 0.5em;
      left: 1.625em;
      width: 0.4375em;
      height: 5.625em;
      transform: rotate(-45deg);

      @if $icon-zoom != 1 {
        zoom: $icon-zoom;
      }
    }

    [class^='swal2-success-line'] {
      display: block;
      position: absolute;
      z-index: 2;
      height: 0.3125em;
      border-radius: 0.125em;
      background-color: $swal2-success;

      &[class$='tip'] {
        top: 2.875em;
        left: 0.8125em;
        width: 1.5625em;
        transform: rotate(45deg);
      }

      &[class$='long'] {
        top: 2.375em;
        right: 0.5em;
        width: 2.9375em;
        transform: rotate(-45deg);
      }

      @if $icon-zoom != 1 {
        zoom: $icon-zoom;
      }
    }

    // Success icon animation
    &.swal2-icon-show {
      @if $swal2-icon-animations {
        .swal2-success-line-tip {
          animation: swal2-animate-success-line-tip 0.75s;
        }

        .swal2-success-line-long {
          animation: swal2-animate-success-line-long 0.75s;
        }

        .swal2-success-circular-line-right {
          animation: swal2-rotate-success-circular-line 4.25s ease-in;
        }
      }
    }
  }
}
